import React, { Component } from 'react';
import '../css/MainPage.css';
import playIcon from '../images/playIcon.svg';
import attentionPlayIcon from '../images/attentionPlayIcon.svg';

class GamesListing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			baseColor: {
				'HJE-oa2z_l-': '#C6A40B',
				NyM_JGWcx: '#5F1B5E',
				Hk3bj6nMdgb: '#8383D9',
				Bk25EzR7T: '#0087B8',
				S1Wrpf1v5ym: '#6235CB',
				By5syVo5: '#0068BB',
				rJsl0KSbuN: '#8D6AB2',
				'HyBw2v2-F': '#F5F5F5'
			}
		};
	}
	render() {
		let { baseColor } = this.state;
		let { gamesResponse, isLoading } = this.props;
		return (
			<div className="game-listing">
				{gamesResponse &&
					gamesResponse.map((dt, ind) => {
						return (
							<a className="game-box" href={dt.url} target="_blank">
								<img src={dt.assets && dt.assets.brick} alt="imgGame" className="game-box-image" />
								<div className="game-box-footer" style={{ background: baseColor[dt.code] }}>
									<p
										className="game-box-footerText"
										style={{ color: dt.code == 'HyBw2v2-F' ? '#000000' : '#FFFFFF' }}
									>
										{dt.name.en}
									</p>
									<img
										src={dt.code == 'HyBw2v2-F' ? attentionPlayIcon : playIcon}
										alt="arrow"
										className="game-box-footerImage"
									/>
								</div>
							</a>
						);
					})}
			</div>
		);
	}
}

export default GamesListing;
