import React, { Component } from 'react';
import '../css/MainPage.css';
import GamesListing from './GamesListing';
import backArrow from '../images/backArrow.svg';
class MainPage extends Component {
	constructor() {
		super();
		this.state = {
			gamesResponse: [],
			isLoading: false
		};
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		fetch(`https://pub.gamezop.com/v3/games?id=Ob5bvrber`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.status == 'failure') {
					this.setState({ isLoading: false });
					alert('Something went wrong');
				} else {
					this.setState({ gamesResponse: data.games, isLoading: false });
				}
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	}
	render() {
		let { gamesResponse, isLoading } = this.state;
		return (
			<div className="main-page">
				{/* <div className="main-header">
					<button className="main-header-image">
						<img src={backArrow} alt="imgBack" />
					</button>
					<p className="main-header-text">Play Games</p>
				</div> */}
				<div className="main-content">
					<p className="main-content-heading">Play Games</p>
					<p className="main-content-subheading">
						Freshen up and sharpen your brain with these engaging games.
					</p>
				</div>
				<GamesListing gamesResponse={gamesResponse} isLoading={isLoading} />
			</div>
		);
	}
}

export default MainPage;
